
// Plugins
import  { RqBusy } from "@/shared/plugins/";
import RqBanner from "@/shared/components/RqBanner.vue";

// initialize Lodash mixin
import LodashMixin from "@/shared/mixins/lodash.mixin";
_.mixin(LodashMixin);

export default {
    install (app) {
        app.use(RqBusy);
        app.component("rq-banner", RqBanner);
    }
};