<template>
    <section />
</template>

<script>

export default {
    name: "RouteRedirectHelper",
    async mounted() {
        const self = this;
        this.$router.push({path: localStorage.getItem("route-redirect-path")});
    },
}
</script>