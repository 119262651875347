<template>
    <section class="view-container no-overflow-container d-flex flex-column">
        <div class="fragment-container">
            <arc-all-assets @show-asset-versions="onShowAssetVersions" />
        </div>
    </section>
</template>
<script>

import { setLogLevel } from "@azure/logger";

setLogLevel("info");

export default {
    name: "AllAssets",
    computed: {
        showBreadcrumb() { return _.get(this, "$route.meta.showBreadcrumb", false); },
        borderlessContent() {
            return _.startsWith(this.$route.name, "settings");
        }
    },
    methods: {
        onShowAssetVersions(e) {
            this.$router.push({ name: "assets:versions", params: { assetId: e.detail.assetId }});
        }
    }
}
</script>