import { defineStore } from "pinia";

export const AuthStateStore = defineStore({
    id: "authState",
    state: () => ({
        isAuthenticated: false,
        identityToken: "",
        identityTokenParsed: {},
    }),
    getters: {
        tokenExpirationTimeout: state => {
            let iat =  _.getNumber(state.identityTokenParsed, 'iat', 0);
            let exp = _.getNumber(state.identityTokenParsed, 'exp', 0);
            // This is effectively the millisecond-based setting for "Access Token Lifespan" in Keycloak.
            let sessionTimeout = (exp - iat) * 1000;
            return sessionTimeout > 0 ? sessionTimeout : 0;
        }
    },
    actions: {
        initializeSession(tokenValues) {
            this.identityToken = tokenValues.token;
            this.identityTokenParsed = tokenValues.tokenParsed;
            this.isAuthenticated = true;
            return true;
        },
        clearToken() {
            // Keycloak
            this.identityToken = "";
            this.identityTokenParsed = {};
            this.isAuthenticated = !!this.identityToken;
        }
    },
});
