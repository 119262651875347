<template>
    <section class="view-container d-flex flex-column">
        <div class="fragment-container">
            <arc-asset-versions :assetId="$route.params.assetId" />
        </div>
    </section>
</template>

<script>
    import { AssetApi } from "@/api";

    export default {
        name: "AssetVersions",
        async mounted() {            
            let routeName = this.$route.name;
            let currentPath = _.find(this.$route.matched, match => match.name === routeName);
            const breadcrumbs = currentPath.meta.breadcrumbs;
            localStorage.setItem("route-redirect-path", this.$route.path);
            
            await AssetApi.getByAssetId(this.$route.params.assetId)
                .then((result) => {              
                    if (breadcrumbs == undefined && currentPath.meta.label != result.name) {
                        this.$router.push({ name: "assets:redirect", params: { assetId: result.assetId}});                        
                        localStorage.setItem("meta-label", result.name);
                    }
                    if (breadcrumbs && parseInt(breadcrumbs?.length) > 0 && breadcrumbs[breadcrumbs?.length - 1].meta.label != result.name){                       
                        this.$router.push({ name: "assetTypes:assets:redirect", params: { assetId: result.assetId}});
                        localStorage.setItem("breadcrumb", result.name);
                        localStorage.setItem("meta-label", result.assetTypeName);
                    }                 
                    
                });
        }
    };
</script>
