import axios from "axios";
import { AuthStateStore } from "@/stores/authState";

const isDevelopment = process.env.NODE_ENV === "development";
const baseURL = isDevelopment
    ? "https://localhost:7182"
    : `${window.location.origin}/api/cs-asset/v2`;

const parseUrl = url => _.startsWith(url, '/') ? url.substr(1) : url;
class HttpWrapperClass {

    constructor () {
        this.client = null;
        this.init();
    }

    init() {
        if(!_.isNil(this.client)) return;
        this.client = axios.create({ baseURL, timeout: 900000 });
    }

    get (url, config) { return this.fetch("get", url, null, config); }
    post (url, data, config) { return this.fetch("post", url, data, config); }
    put (url, data, config) { return this.fetch("put", url, data, config); }
    delete (url, config) { return this.fetch("delete", url, null, config); }

    getAuthHeader(cfg) {
        let config = cfg || {};

        if(!config.disableAuthStore) {
            let authStateStore = AuthStateStore();        
            let token = _.get(authStateStore, 'identityToken', '');
            if (!_.has(cfg, "headers.Authorization")) {
                config = _.merge(config, { headers: { Authorization: `Bearer ${token}` } });
            }
        }

        config = _.merge(config, {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate, pre-check=0, post-check=0, max-age=0, s-maxage=0',
                'Pragma': 'no-cache',
            }
        });

        return config;
    }

    fetch(method, url, data, config) {
        this.init();//ensures proper base URL; returns if already initialized
        let apiUrl = parseUrl(url);
        let apiConfig = this.getAuthHeader(config);
        let apiPromise = null;
        switch(method) {
            case "get": apiPromise = this.client.get(apiUrl, apiConfig); break;
            case "post": apiPromise = this.client.post(apiUrl, data, apiConfig); break;
            case "put": apiPromise = this.client.put(apiUrl, data, apiConfig); break;
            case "delete": apiPromise = this.client.delete(apiUrl, apiConfig); break;
        }
        return new Promise((resolve, reject) => {
            apiPromise
                .then(response => {
                    resolve(response.data);
                })
                .catch(err => {
                    console.error(err);
                    reject(err);
                });
        });
    }
}

export const HttpService = new HttpWrapperClass();