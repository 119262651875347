// NOTE: These enums correspond to enums in the API

export const roles = {
    NONE: "None",
    OWNER: "Owner",
    VIEW: "View",
    VIEW_AND_EDIT: "ViewAndEdit",
    RQ_ONE_CUSTOMER: "RQOneCustomer",
    HORIZON_CUSTOMER: "HorizonCustomer",
    CCE_CUSTOMER: "CCECustomer"
};

export const statusNames = {
    INACTIVE: "Inactive",
    ACTIVE: "Active",
    DELETED: "Deleted"
};