import Keycloak from "keycloak-js";
import { AuthStateStore } from "@/stores/authState";

class IdentityService {
    constructor() {
        this.intervalName = "token-refresh";
        this.source = "identity.service.js";
        this.config = null;
        this.keycloak = null;
    }

    get identityToken() {
        let kcValues = _.pick(this.keycloak, ["token", "tokenParsed"]);
        return _.merge({}, { token:"", tokenParsed: "" }, kcValues);
    }

    get isAuthenticated() {
        return _.getBool(this, "keycloak.authenticated");
    }

    initialize({ url, clientId, realm, logoutRedirectUri }) {
        let self = this;
        if (!self.config) self.config = { url, clientId, realm, logoutRedirectUri };
        self.keycloak = new Keycloak({ url, clientId, realm, logoutRedirectUri });
        return self.keycloak.init({
                    onLoad: "check-sso",
                    silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`
                })
                .then(auth => {
                    let redirectToLogin = !auth;

                    if(redirectToLogin) {
                        let redirectUrl = `${window.location.origin}${window.location.pathname}`;
                        window.location.href = self.createLoginUrl(redirectUrl);
                        return false;
                    }

                    // Set WalkMe variable
                    window.arc_userID = `${self.keycloak.tokenParsed.email}:${self.keycloak.tokenParsed.tenants}`;

                    let authStateStore = AuthStateStore();
                    authStateStore.initializeSession(self.identityToken);
                    return true;
                });
    }

    refreshToken() {
        // Keycloak's updateToken method doesn't produce the desired behavior, so just re-initialize from scratch
        this.initialize(this.config);
    }

    createLoginUrl(redirectUri=null) {
        let urlResult = this.keycloak.createLoginUrl({ redirectUri });
        return urlResult || redirectUri;
    }

    createLogoutUrl(redirectUri=null) {
        let urlResult = this.keycloak.createLogoutUrl({ redirectUri });
        return urlResult || redirectUri;
    }

    logOut(redirectUri) {
        let loginUrl = redirectUri && this.createLoginUrl(redirectUri);
        this.keycloak.logout(loginUrl);
        let authStateStore = AuthStateStore();
        authStateStore.clearToken();
    }
}

export const Identity = new IdentityService();