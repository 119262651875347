/* global gtag */
/* global dataLayer */
export function loadGoogleAnalytics() {
    let gtagId = process.env.VUE_APP_GTAG_ID;
    let gtagLoadingScript = document.createElement('script');
    gtagLoadingScript.type = 'text/javascript';
    gtagLoadingScript.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;
    gtagLoadingScript.async = true;
    gtagLoadingScript.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', gtagId);
    }
    document.head.appendChild(gtagLoadingScript);
}