<template>
    <nav class="app-breadcrumb" aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li v-for="(route, index) in eligibleRoutes"
                :key="index"
                class="breadcrumb-item"
                :class="{ 'active': route.name === $route.name }">
                <span v-if="route.name === $route.name">{{route.meta.label}}</span>
                <RouterLink
                    v-else
                    :to="route">
                    {{route.meta.label}}
                </RouterLink>
            </li>
        </ol>
    </nav>
</template>

<script>
    export default {
        name: "RqBreadcrumbs",
        data() {
            return {
                lastRoute: null
            };
        },
        computed: {
            eligibleRoutes() {
                // Use our custom breadcrumbs if we pass them, otherwise rely on the router to pass things through
                if (this.$route.meta.breadcrumbs) {
                    // Will need to revisit if designs add breadcrumbs with more than 3 levels
                    this.$route.meta.breadcrumbs.find(b => b.meta.updateLabel).meta.label = localStorage.getItem("meta-label");
                    
                    return this.$route.meta.breadcrumbs;
                }

                return _.filter(this.$route.matched, r => !_.isEmpty(_.get(r, "meta.label", null)));
            }
        },
        watch: {
            $route: {
                handler(newRoute, oldRoute) {
                    this.lastRoute = oldRoute?.matched.find(r => r.name === oldRoute.name);
                },
                immediate: true
            }
        }
    }
</script>