import { defineStore } from "pinia";

export const AppStateStore = defineStore({
    id: "appState",
    state: () => ({
        globalSearchText: "",
        invokeGlobalSearch: "",
        busyCount: 0,
        toast: {}
    }),
    getters: {
        isBusy: state => state.busyCount > 0
    },
    actions: {
        startWait() {
            this.busyCount++;
        },
        endWait() {
            if(this.busyCount === 0) return;
            this.busyCount--;
        },
        addToast(toast) {
            this.toast = toast;
        },
        clearToast() {
            this.toast = null;
        }
    },
});
