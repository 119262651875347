import routes from "./routes";

export default {
    path: "/settings",
    name: "settings",
    component: () => import("./SettingsMain.vue"),
    meta: {
        borderless: true,
        headerTitle: "Settings"
    },
    children: routes
};