const GeneralSettings = () => import("./views/GeneralSettings.vue");
const Attributes = () => import("./views/Attributes.vue");
const AssetTypes = () => import("./views/AssetTypes.vue");
const UsersAndGroups = () => import("./views/UsersAndGroups.vue");
const NotificationSettings = () => import("./views/NotificationSettings.vue");

export default [
    // NOT PART OF MVP
    // {
    //     path: "",
    //     name: "settings:general",
    //     component: GeneralSettings,
    //     meta: { label: "General" }
    // },
    {
        path: "attributes",
        name: "settings:attributes",
        component: Attributes,
        meta: { label: "Attributes" }
    },
    {
        path: "assetTypes",
        name: "settings:assetTypes",
        component: AssetTypes,
        meta: { label: "Asset Types" }
    },
    {
        path: "users-and-groups",
        name: "settings:usersandgroups",
        component: UsersAndGroups,
        meta: { label: "Users & Groups" }
    },
    // NOT PART OF MVP
    // {
    //     path: "notifications",
    //     name: "settings:notifications",
    //     component: NotificationSettings,
    //     meta: { label: "Notifications" }
    // },
];