<template>
    <section class="view-container no-overflow-container d-flex flex-column">
        <div class="fragment-container">
            <arc-asset-type-assets
                :selectedAssetType="assetType"
                @show-asset-versions="onShowAssetVersions"
            />
        </div>
    </section>
</template>

<script>
    import { AssetTypeApi } from "@/api";

    export default {
        name: "AssetTypeAssets",
        data() {
            return {
                assetType: null,
            }
        },
        async mounted() {
            const self = this;
            
            let routeName = this.$route.name;
            let currentPath = _.find(this.$route.matched, match => match.name === routeName);
            localStorage.setItem("route-redirect-path", this.$route.path);
            
            await AssetTypeApi.getById(this.$route.params.assetTypeId)
                .then((result) => {                                                   
                    self.assetType = result;
                    //Cannot set meta label or dispatch event here because breadcrumb has already loaded. Result is breadcrumb shows previous location
                    //Solution: check if meta label matches assetType name, if not, use redirect to get correct label
                    if (currentPath.meta.label != self.assetType.name)
                    {                        
                        this.$router.push({ name: "assetTypes:redirect", params: { assetTypeId: self.assetType.assetTypeId}});                        
                        localStorage.setItem("meta-label", self.assetType.name);          
                    }
            });
        },
        methods: {
            onShowAssetVersions(e) {
                this.$router.push({ name: "assetTypes:assets:versions", params: { assetId: e.detail.assetId }});
            }
        }
    };
</script>