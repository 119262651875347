<template>
    <arc-login-failed
        v-if="disallowLogin"
        :user="loginUser"
        @log-out="onLogOut"
    />
    <BaseApp v-else />
</template>

<script>
    import BaseApp from "./BaseApp.vue";
    import { statusNames } from "@/shared/helpers/enums";
    import { Identity } from "@/shared/services/identity.service";
    import UsersApi from "@/api/users";

    export default {
        name: "App",
        components: {
            BaseApp
        },
        data() {
            return {
                disallowLogin: false,
                loginUser: null
            };
        },
        async mounted() {
            await UsersApi.getUserAtLogin().then(user => this.loginUser = user);
            this.disallowLogin = this.loginUser?.status !== statusNames.ACTIVE;
        },
        methods: {
            onLogOut() {
                Identity.logOut(location.href);
            }
        }
    }
</script>