import { AppStateStore } from "@/stores/appState";

export default {
    install(app) {
        const appState = AppStateStore();
        const startWait = () => appState.startWait();
        const endWait = () => appState.endWait();
        const wait = promise => {
            startWait();
            return promise
                .finally(() => {
                    endWait();
                });
        };
        const rqBusy = {
            startWait,
            endWait,
            wait
        };
        app.config.globalProperties.$rqBusy = rqBusy;
    }
};