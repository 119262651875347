import { httpDelete, httpGet, httpPost, httpPut } from "./helpers";

export default {
    search: request => httpPost("asset/search", request),
    checkOut: (id) => httpPost(`asset/${id}/checkout`),
    checkIn: (id) => httpPost(`asset/${id}/checkin`),
    clone: (id) => httpPost(`asset/${id}/clone`),
    copyFileFromTemp: data => httpPost(`asset/copyfilefromtemp`, data),
    create: data => httpPost("asset", data),
    delete: (id) => httpDelete(`asset/${id}`),
    deleteAssets: ids => httpPost("asset/delete", ids),
    getAll: () => httpGet("asset"),
    getByAssetId: (id) => httpGet(`asset/${id}`),
    getAssetDownloadUrl(id){ return httpGet(`asset/${id}/downloadurl`) },
    getAssetUploadUrl(id){ return httpGet(`asset/${id}/uploadurl`) },
    getNewFileUploadUrl(id){ return httpGet(`asset/${id}/newfileuploadurl`) },
    getLinkedAssets: (id) => httpGet(`asset/${id}/children`),
    syncVersions(id){ return httpPost(`asset/${id}/syncversions`)},
    undoCheckOut: (id) => httpPost(`asset/${id}/undocheckout`),
    updateAssetVersionComments: (id, versionId, data) => httpPut(`asset/${id}/version/${versionId}`, data)
};