<template>
    <section class="view-container d-flex flex-column">
        <div class="fragment-container">
            <arc-page-not-found />
        </div>
    </section>
</template>

<script>
    export default {
        name: "PageNotFound"
    }
</script>