import Search from "./views/Search.vue";
import AssetVersions from "@/shared/components/AssetVersions.vue";
import SimpleRouteView from "@/shared/components/SimpleRouteView.vue";
import RouteRedirectHelper from "@/shared/components/RouteRedirectHelper.vue";

import { routeNames } from "@/shared/models/route-names";

export default [
    // NOT PART OF MVP
    // {
    //     name: routeNames.HOME_DASHBOARD,
    //     path: "dashboard",
    //     component: DashboardView,
    //     meta: {
    //         label: "Dashboard"
    //     },
    // },
    {
        path: "search",
        component: SimpleRouteView,
        meta: {
            label: "Search"
        },
        children: [
            {
                name: routeNames.HOME_SEARCH,
                path: "",
                component: Search
            },
            {
                name: "search:versions",
                path: ":assetId/versions",
                component: AssetVersions,
                beforeEnter(to, from, next) {
                    let routeName = to.name;
                    let currentPath = _.find(to.matched, match => match.name === routeName);
                    currentPath.meta.label = localStorage.getItem("meta-label");                    
                    next();
                },
                meta: {
                    showBreadcrumb: true
                },
                props: (to) => {
                    let route = to.matched.find(match => match.name === to.name);
                    return {
                        assetId: route.meta.assetId
                    };
                }
            },
            {
                name: "search:redirect",
                path: ":assetId/redirect",
                component: RouteRedirectHelper,
                meta: {
                    showBreadcrumb: false
                }
            },
        ]
    }
];