<template>
    <arc-registration
        :apiKey="apiKey"
        :customer="customer"
        :loginUrl="loginUrl"
    />
</template>
<script>
    export default {
        name: "RegisterApp",
        props: {
            customer: { type: Object },
            apiKey: { type: String },
            loginUrl: { type: String }
        }
    };
</script>