import AllAssets from "./views/AllAssets.vue";
import AssetVersions from "@/shared/components/AssetVersions.vue";
import AssetTypes from "./views/AssetTypes.vue";
import AssetTypeAssets from "./views/AssetTypeAssets.vue";
import RouteRedirectHelper from "@/shared/components/RouteRedirectHelper.vue";
import SimpleRouteView from "@/shared/components/SimpleRouteView.vue";

export default [
    {
        path: "allAssets",
        component: SimpleRouteView,
        meta: {
            label: "All Assets"
        },
        children: [
            {
                name: "assets",
                path: "",
                component: AllAssets
            },
            {
                name: "assets:versions",
                path: ":assetId/versions",
                component: AssetVersions,
                beforeEnter(to, from, next) {
                    let routeName = to.name;
                    let currentPath = _.find(to.matched, match => match.name === routeName);
                    currentPath.meta.label = localStorage.getItem("meta-label");                    
                    next();
                },
                meta: {
                    showBreadcrumb: true
                },
                props: true
            },
            {
                name: "assets:redirect",
                path: ":assetId/redirect",
                component: RouteRedirectHelper,
                meta: {
                    showBreadcrumb: false
                }
            },
        ]
    },
    {
        path: "assetTypes",
        component: SimpleRouteView,
        meta: {
            label: "Asset Types"
        },
        children: [
            {
                name: "assetTypes",
                path: "",
                component: AssetTypes,
            },
            {
                name: "assetTypes:assets",
                path: ":assetTypeId/assets",
                component: AssetTypeAssets,
                beforeEnter(to, from, next) {
                    let routeName = to.name;
                    let currentPath = _.find(to.matched, match => match.name === routeName);
                    currentPath.meta.label = localStorage.getItem("meta-label");                    
                    next();
                },
                meta: {
                    showBreadcrumb: true
                }
            },
            {
                name: "assetTypes:redirect",
                path: ":assetTypeId/redirect",
                component: RouteRedirectHelper,
                meta: {
                    showBreadcrumb: false
                }
            },
            {
                name: "assetTypes:assets:versions",
                path: ":assetTypeId/assets/:assetId/versions",
                component: AssetVersions,
                beforeEnter(to, from, next) {
                    let routeName = to.name;
                    let currentPath = _.find(to.matched, match => match.name === routeName);
                    currentPath.meta.label = localStorage.getItem("meta-label");

                    //Update breadcrumb label if it's being used as the 3rd or deeper breadcrumb
                    const breadcrumbs = currentPath.meta.breadcrumbs;
                    if (breadcrumbs) breadcrumbs[breadcrumbs.length - 1].meta.label = localStorage.getItem("breadcrumb");                    
                    
                    next();
                },
                meta: {
                    showBreadcrumb: true,
                    breadcrumbs: [
                        {
                            name: "assetTypes",
                            meta: {
                                label: "Asset Types"
                            }
                        },
                        {
                            name: "assetTypes:assets",
                            meta: {
                                updateLabel: true,
                                // This will get overridden by RqBreadcrumbs
                                label: null
                            },
                        },
                        {
                            name: "assetTypes:assets:versions",
                            meta: {
                                // This will get overridden in this route's beforeEnter
                                label: null
                            }
                        }
                    ]
                },
                props: true,
            },
            {
                name: "assetTypes:assets:redirect",
                path: ":assetTypeId/assets/:assetId/redirect",
                component: RouteRedirectHelper,
                meta: {
                    showBreadcrumb: false
                }
            },
        ]
    },
    // {
    //     name: "starred",
    //     path: "starred",
    //     component: FilesView,
    //     meta: {
    //         label: "Starred Files"
    //     },
    //     props: () => ({
    //         userFavorites: true
    //     })
    // },
];