<template>
    <aside class="app-sidebar navbar navbar-dark py-0" :class="{ 'app-sidebar-collapsed': collapsed }">
        <div class="container-fluid">
            <div class="app-logo">
                <div class="app-logo-content"></div>
            </div>
            <a href="#" class="collapsed-state-toggle" @click.prevent="collapsed=!collapsed">
                <i class="fal fa-angle-left" :class="{ 'fa-rotate-180': collapsed }"></i>
            </a>
            <nav class="nav navbar-nav flex-column">
                <!-- NOT PART OF MVP -->
                <!-- <RouterLink :to="{ name: 'dashboard' }" active-class="active" class="nav-link">
                    <i class="fal fa-house"></i>
                    <span class="nav-link-text">Dashboard</span>
                </RouterLink> -->
                <RouterLink :to="{ name: 'assets' }" active-class="active" class="nav-link">
                    <i class="fal fa-files"></i>
                    <span class="nav-link-text">All Assets</span>
                </RouterLink>
                <RouterLink :to="{ name: 'assetTypes' }" active-class="active" class="nav-link">
                    <i class="fal fa-grid"></i>
                    <span class="nav-link-text">Asset Types</span>
                </RouterLink>
                <!-- NOT PART OF MVP -->
                <!-- <RouterLink :to="{ name: 'starred' }" active-class="active" class="nav-link">
                    <span class="starred-stacked-icon">
                        <i class="fal fa-file"></i>
                        <i class="fas fa-circle-star"></i>
                    </span>
                    <span class="nav-link-text">Starred</span>
                </RouterLink> -->
            </nav>
        </div>
    </aside>
</template>

<script>
    export default {
        name: "AppSidebar",
        data() {
            return {
                collapsed: false
            }
        }
    }
</script>