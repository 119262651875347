<template>
    <transition>
        <div v-show="showBanner" class="banner">
            <div :class="colorClass"></div>
            <span v-if="icon" :class="iconClass"><i :class="icon"></i></span>
            <span v-if="message" class="banner-message" v-html="message"></span>
            <i v-if="dismissable" class="fas fa-times banner-dismiss" @click="onDismiss"></i>
        </div>
    </transition>
</template>

<script>
    export default {
        props: {
            dismissable: { type: Boolean, default: true },
            icon: { default: "" },
            message: { default: "" },
            persistable: { type: Boolean, default: false },
            variant: { default: "default" },
            visible: { type: Boolean, default: false },
        },
        data() {
            return {
                dismissed: false
            };
        },
        computed: {
            colorClass() { return ["banner-color", `banner-color-${this.variant}`]; },
            iconClass() { return ["banner-icon", `banner-icon-${this.variant}`]; },
            showBanner() { return this.visible && !this.dismissed; }
        },
        updated() {
            const self = this;
            if (self.showBanner && !self.persistable) setTimeout(() => { self.onDismiss(); }, 5000);
        },
        watch: {
            visible: {
                handler(newVal, oldVal) {
                    if(newVal === oldVal) return;
                    this.dismissed = false;
                },
                immediate: true
            }
        },
        methods: {
            onDismiss() {
                const self = this;
                self.dismissed = true;
                self.$emit("dismiss");
            }
        }
    }
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity .75s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>