class FragmentScriptHelperClass {
    constructor(){
        let isDevelopment = process.env.NODE_ENV === "development";
        this.baseURL = isDevelopment
            ? "https://localhost:7182/FragmentJs"
            : `${window.location.origin}/api/cs-asset/v2/FragmentJs`;
    }

    get arcFragmentsScriptUrl(){
        return this.baseURL + "/arc-fragments.js"
    }
}

export const FragmentScriptHelper = new FragmentScriptHelperClass();

export const loadScript = (src, type=null) => new Promise(resolve => {
    let scriptElement = document.createElement("script")
    scriptElement.setAttribute("src", src);
    if(type) scriptElement.setAttribute("type", type);
    scriptElement.addEventListener("load", function() {
        resolve();
    });
    document.head.appendChild(scriptElement);
});