<template>
    <section class="view-container d-flex flex-column">
        <div class="fragment-container">
            <arc-asset-types @show-assets="onShowAssets" />
        </div>
    </section>
</template>

<script>
    export default {
        name: "AssetTypes",
        methods: {
            onShowAssets(e) {
                this.$router.push({ name: "assetTypes:assets", params: { assetTypeId: e.detail[0].assetTypeId }});
            }
        }
    }
</script>